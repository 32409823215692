import React from "react";
import classNames from "classnames";
import { Text } from "@atoms";

const CopySection = ({ heading, subHeading, copy, twoColumns, className }) => {
  return (
    <div className={className}>
      {heading && <Text variant="h2">{heading}</Text>}
      {subHeading && <Text variant="h3">{subHeading}</Text>}
      {copy && (
        <div
          className={classNames("mt-10 gap-10", {
            "sm:columns-2": twoColumns,
            "max-w-xl": !twoColumns,
          })}
        >
          <Text className="prose max-w-none text-sm prose-p:break-inside-avoid">
            {copy}
          </Text>
        </div>
      )}
    </div>
  );
};

export default CopySection;
