import React, { useState } from "react";
import { animate, m } from "framer-motion";
import { Text } from "@atoms";

const StatLong = ({ stat, prefixLabel, suffixLabel, className }) => {
  const [animatedNumber, setAnimatedNumber] = useState(0);

  const otherbits = stat.replace(/[0-9]/g, "");

  // Animate the numbers
  const updateNumbers = numberTo => {
    animate(0, numberTo, {
      duration: Math.min(numberTo / 3, 3),
      onUpdate: value => {
        setAnimatedNumber(value.toFixed());
      },
      transition: {
        ease: "circIn",
      },
    });
  };
  return (
    <m.li
      className={className}
      viewport={{ once: true, amount: "all", margin: "20px 0px 20px 0px" }}
      onViewportEnter={() => updateNumbers(parseInt(stat, 10))}
    >
      <Text
        variant="metricLabel"
        className="flex w-full flex-shrink-0 justify-center text-center text-white sm:w-auto sm:max-w-[40%] sm:justify-start"
      >
        {prefixLabel}
      </Text>
      <Text
        variant="metric"
        className="max-1/3 flex flex-shrink-0 justify-center text-center text-white"
      >
        {`${animatedNumber}${otherbits}`}
      </Text>
      <Text
        variant="metricLabel"
        className="sm:text-keft flex w-full flex-shrink-0 justify-center text-center text-white sm:w-auto sm:max-w-[40%] sm:justify-end"
      >
        {suffixLabel}
      </Text>
    </m.li>
  );
};
export default StatLong;
