import React, { useState } from "react";
import { animate, m } from "framer-motion";
import { Text } from "@atoms";

const ComponentName = ({ stat, label, className }) => {
  const [animatedNumber, setAnimatedNumber] = useState(0);

  const otherbits = stat.replace(/[0-9]/g, "");

  // Animate the numbers
  const updateNumbers = numberTo => {
    animate(0, numberTo, {
      duration: Math.min(numberTo / 3, 3),
      onUpdate: value => {
        setAnimatedNumber(value.toFixed());
      },
      transition: {
        ease: "circIn",
      },
    });
  };
  return (
    <m.li
      className={className}
      viewport={{ once: true, amount: "all", margin: "20px 0px 20px 0px" }}
      onViewportEnter={() => updateNumbers(parseInt(stat, 10))}
    >
      <Text variant="metric" className="text-center text-white">
        {`${animatedNumber}${otherbits}`}
      </Text>
      <Text
        variant="metricLabel"
        className="w-full text-center text-white sm:w-auto sm:max-w-[50%] sm:text-left"
      >
        {label}
      </Text>
    </m.li>
  );
};

export default ComponentName;
